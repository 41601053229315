<template>
  <v-form autocomplete="on" @submit.prevent="reset">
    <v-text-field
      id="email"
      type="text"
      name="email"
      autocomplete="username"
      v-model="email"
      :placeholder="$t('general.email_address')"
      :error="$v.email.$error"
      outlined
    ></v-text-field>

    <v-btn
      type="submit"
      color="primary"
      elevation="5"
      :loading="isResetting"
      :disabled="email === ''"
      x-large
      block
    >
      {{ $t('login.recover_password') }}
    </v-btn>
  </v-form>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import { requestResetPassword } from '@access/services/accessService';

export default {
  name: 'ResetPasswordForm',
  data() {
    return {
      email: '',
      resetDone: false,
      resetting: false,
    };
  },
  computed: {
    isResetDone() {
      return this.resetDone;
    },
    isResetting() {
      return this.resetting;
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    reset() {
      this.resetting = true;

      this.$v.email.$touch();
      if (this.$v.email.$error) {
        this.resetting = false;

        return;
      }

      requestResetPassword({ email: this.email })
        .then(() => {
          this.$router.push({
            name: 'passwordForgottenDone',
          });
        })
        .finally(() => {
          this.resetting = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
